import styled from 'styled-components';

export default function Gabaedos() {
  return <GabaedosContainer>아아웅아아ㅏ</GabaedosContainer>;
}

const GabaedosContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;
